// import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import './headerMenu.scss';

const HeaderMenu = () => {
    const handleMenuOpenToggle = () => {
        document.documentElement.classList.toggle('menu-open');
        document.documentElement.classList.toggle('lock');
    };

    const handleMenuClose = () => {
        const isMenuOpen =
            document.documentElement.classList.contains('menu-open');
        const isLock = document.documentElement.classList.contains('lock');
        if (isMenuOpen && isLock) {
            document.documentElement.classList.remove('menu-open');
            document.documentElement.classList.remove('lock');
        }
    };
    const windowWidth = window.innerWidth;
    // let mobileWidth = windowWidth <= 991.98 && windowWidth > 767.98;
    let mobileWidth = windowWidth <= 767.98;
    let mobileSmallWidth = windowWidth < 426;

    // const targetRef = useRef(null);

    // const handleClick = () => {
    // 	targetRef.current.scrollIntoView({
    // 		behavior: 'smooth',
    // 		block: 'start',
    // 	});
    // };

    return (
        <div className="header__menu menu">
            <nav className="menu__body">
                <ul className="menu__list">
                    <li className="menu__item">
                        <HashLink
                            to="/#about"
                            smooth={true}
                            duration={500}
                            offset={-65}
                            className="menu__link"
                            onClick={handleMenuClose}
                        >
                            About
                        </HashLink>
                    </li>
                    {/* <li className="menu__item">
            <HashLink
              to="/#agenda"
              smooth={true}
              duration={500}
              className="menu__link"
              onClick={handleMenuClose}
            >
              Agenda
            </HashLink>
          </li> */}
                    <li className="menu__item">
                        <HashLink
                            to="/#code"
                            smooth={true}
                            duration={500}
                            className="menu__link"
                            onClick={handleMenuClose}
                        >
                            E-certificate
                        </HashLink>
                    </li>
                    <li className="menu__item">
                        <HashLink
                            to="/#speakers"
                            smooth={true}
                            duration={500}
                            className="menu__link"
                            onClick={handleMenuClose}
                        >
                            Speakers
                        </HashLink>
                    </li>
                    <li className="menu__item">
                        <HashLink
                            to="/#committees"
                            smooth={true}
                            duration={500}
                            className="menu__link"
                            onClick={handleMenuClose}
                        >
                            Committees & Submission
                        </HashLink>
                    </li>
                    <li className="menu__item">
                        <HashLink
                            to="/#dates"
                            smooth={true}
                            duration={500}
                            className="menu__link"
                            onClick={handleMenuClose}
                        >
                            Dates {!mobileSmallWidth ? `& News` : null}
                        </HashLink>
                    </li>
                    {!mobileWidth ? (
                        <li className="menu__item">
                            <HashLink
                                to="/#partners"
                                smooth={true}
                                duration={500}
                                className="menu__link"
                                onClick={handleMenuClose}
                            >
                                Partners
                            </HashLink>
                        </li>
                    ) : null}
                    <li className="menu__item">
                        <HashLink
                            to="/#gallery"
                            smooth={true}
                            duration={500}
                            className="menu__link"
                            onClick={handleMenuClose}
                        >
                            Background
                        </HashLink>
                    </li>
                    <li className="menu__item">
                        <HashLink
                            to="/#contacts"
                            smooth={true}
                            duration={500}
                            className="menu__link"
                            onClick={handleMenuClose}
                        >
                            Contacts
                        </HashLink>
                    </li>
                </ul>
            </nav>
            <a
                rel="noreferrer"
                target="_blank"
                href="https://docs.google.com/forms/d/e/1FAIpQLSdm8WnOT9JmEIASqwKfnU8CBm_pekDjwbQeUSSo37sGV1nLgg/viewform?usp=sharing"
                className="menu__link menu__link_registration link-action"
            >
                Registration
            </a>
            <button
                onClick={handleMenuOpenToggle}
                type="button"
                className="menu__icon icon-menu"
            >
                <span></span>
            </button>
        </div>
    );
};

export default HeaderMenu;
